@charset "utf-8";

@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@400;700;900&display=swap");
@import url("https://unpkg.com/tailwindcss@2/dist/tailwind.min.css");

$color_links: #3A6C94;

a {
  color: $color_links;
  text-decoration: underline;
}

body {
  font-size: 16px;
  font-family: "Libre Franklin", Arial;
  color: #333;
}

.post-content {
  a {
    color: $color_links;
    text-decoration: underline;
  }
  p {
    margin-bottom: 1.5rem;
  }
  p {
    margin-bottom: 1.5em;
  }
  h2 {
    font-weight: 700;
    margin: 48px 0 16px 0;
    font-size: 22px;
    text-transform: uppercase;
  }
  h2:first-child {
    margin-top: 0;
  }
  h3 {
    margin: 32px 0 16px 0;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
  }
  em {
    font-style: italic;
  }
  strong {
    font-weight: 600;
  }
  ul, ol {
    margin: 0 0 24px 12px;
    line-height: 1.5;
  }
  ol {
    list-style: none;
    counter-reset: my-awesome-counter;

    li {
      counter-increment: my-awesome-counter;
    }
    li::before {
      content: counter(my-awesome-counter) ". ";
      font-size: 14px;
      font-weight: bold;
    }
  }
  li {
    margin: 0 0 8px 0;
    padding: 0 0 0 20px;
    line-height: 1.5;
  }
  li:before {
    content: '•';
    font-size: 24px;
    margin: 0 8px 0 -18px
  }
  img {
    width: 100%;
    height: auto;
  }
  blockquote:before {
    content: "“";
    font-size: 160px;
    position: absolute;
    margin-top: .18em;
    margin-left: -0.29em;
    opacity: .2;
  }
  blockquote {
    position: relative;
    margin: 5em 0;
    line-height: 1.5em;
    font-size: 1.25rem;
    // background: #cbd5e0;

    p:first-child {
      font-size: 1.5rem;
      font-weight: bold;
    }

    p:last-child {
      margin-bottom: 0;
    }

    footer {
      font-size: .85rem;
    }
  }
  .blockquote_light {
    opacity: .60;
    position: relative;
    padding: 0 0 0 1.5em;
    margin: 0 0 2em 0;
  }
  .blockquote_light:before {
    display: block;
    content: "";
    position: absolute;
    color: #DFDFDF;
    left: 0;
    bottom: 0;
    top: 0;
    width: 5px;
    background: #DDD;
  }
  blockquote.quote {
    font-weight: 700;
    font-size: 24px;
    display: block;
    width: 80%;
    line-height: 32px;
    margin: 2em 15% 2em 17%;
    padding: .5em 0 0 0;
    background: #FFF;
    position: relative;
    z-index: 10;
    cite {
      display: block;
      font-size: 18px;
      font-style: normal;
      opacity: .5;
    }
  }
  blockquote.quote:before {
    display: block;
    content: "\201C";
    font-size: 6em;
    position: absolute;
    line-height: 1;
    color: #DFDFDF;
    left: -22%;
    top: -14px;
    z-index: -1;
  }
  figure:first-child {
    margin-top: 0;
  }
  figure {
    margin: 2em 0;
  }
  caption, figcaption {
    width: 100%;
    display: block;
    font-size: .8em;
    color: #a0aec0;
    line-height: 1.3em;
    margin: .5em 0 0 0;
  }
  img.outline {
    margin: 2em 0;
    border: 1px solid #DDD;
  }
  img.shadow {
    box-shadow: 0 0 6px 1px rgba(0,0,0,0.15);
  }
  @media (min-width: 1200px) {
    img.bigger {
      max-width: initial;
      width: 128%;
      margin-left: -14%;
    }
  }
  p.lead {
    font-size: 1.25rem;
  }
}

.video_wrapper {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin-bottom: 2em;
}
@media (min-width: 1500px) {
  .video_wrapper.bigger {
    width: 144%;
    margin: 0 0 0 -22%;
    padding-bottom: 78.25%;
    margin-bottom: 2em;
  }
}
.video_wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
